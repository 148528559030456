
CookiesVisual {
	.policy-container.hidden {
		opacity: 0 !important;
		/*display: none;*/
		bottom: -50%;
	}

	.policy-container.visible {
		opacity: 1;
		/*display: flex;*/
		bottom: 0px;
	}

	.policy-container {
		left:0;
		bottom:0;
		position: fixed;
		display: flex;
		width: 100%;
		max-width: 100%;
		background-color: transparent;
		justify-content: center;
		align-items: center;
		transition: all 1s ease, bottom 1s ease;
		z-index: 9999;
	}

	.policy-content {
		border-top-left-radius: 2pt;
		border-top-right-radius: 2pt;
		background-color: white;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		padding:24px 5%;
		border: solid 1px lightgray;
		border-radius: 2px;
	    box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
		opacity: .6;

	}

	.policy-container .left {
		padding:6pt;
	}

	.policy-container .right {
		display: flex;
		flex-direction: row;
	}

	.buttons-container {
		margin-right: 50px;
		white-space: nowrap;
	}

	.button {
		margin:4pt;
		padding: 8px 16px;
		font-size: 12px;
		white-space: nowrap;
		min-width: 100px;
		display: inline-block;
		text-align: center;
		transition: background-color 400ms ease-in 0s, transform 100ms linear 0s;
		border-radius: 3pt !important;
	}

	.button:hover {
		transform:scale(1.050)
	}

	.button.primary {
		background-color: #00ff01;
		border:solid 1px #00ff01;
		color:black;
	}
	.button.primary:hover {
		background-color: #00ff01;
		color:white;
	}

	.button.secondary {
		background-color: white;
		border:solid 1px #000;
		color:black;
	}
	.button.secondary:hover {
		background-color: gray;
		color:white;
	}

	.link {
		font-weight: 900;
		color: black;
		text-decoration: none;
		font-size: 15px;
	}

	.policy-link {
		color: black;
		font-weight: 600;
		text-decoration: underline !important;
	}

}
